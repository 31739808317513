.category {
    max-width: 500px;
    margin: 2rem auto;
}

.category form {
    width: 100%;
    margin-bottom: 2rem;
}

.category form label {
    display: block;
    font-weight: 700;
    letter-spacing: 2x;
    text-transform: uppercase;
    margin-bottom: 1rem;
}

.category form input,
.category form button {
    height: 35px;
    border: none;
    outline: none;
    border-bottom: 1px solid #555;
}

.category form input {
    flex: 1;
}

.category form button {
    width: 80px;
    background: #555;
    color: white;
    margin-left: 10px;
}

.category .category_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    cursor: pointer;
}
