.avatar_comment {
    width: 100px;
    min-width: 100px;
    text-align: center;
    padding: 5px;
}

.avatar_comment img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.avatar_comment small a {
    color: #444;
    text-decoration: none;
    font-weight: 500;
}

.avatar_comment small a:hover {
    color: crimson;
}

.comment_box {
    width: 100%;
    border: 1px solid #ddd;
    margin-bottom: 0.75rem;
}

/*  */
.avatar_reply {
    display: flex;
    margin-bottom: 0.25rem;
    align-items: center;
}

.avatar_reply img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.avatar_reply .reply-text {
    display: block;
    opacity: 0.5;
    font-size: 11px;
}

/*  */
.comment_box .comment_nav {
    cursor: pointer;
    display: none;
}
.comment_box:hover .comment_nav {
    display: block;
}
.comment_box .comment_nav i:hover {
    color: crimson;
}
