* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    transform: translateY(-3px);
}
/*  */
.container {
    min-height: 100vh; /* will cover the 100% of viewport */
    overflow: hidden;
    display: block;
    position: relative;
    padding-bottom: 150px; /* height of your footer */
    width: 100%;
}
.footer {
    margin-top: 10px;
    background: #0066cc;
    position: absolute;
    bottom: 0;
    width: 100%;
}

p img {
    width: 100%;
    image-rendering: pixelated;
}
iframe {
    width: 100%;
    height: 400px;
}

@import url('./auth.css');
@import url('./loading.css');
@import url('./alert.css');
@import url('./profile.css');
@import url('./category.css');
@import url('./home.css');
@import url('./blogs_category.css');
@import url('./comments.css');
